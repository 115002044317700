import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { services } from "../services";
import { constants } from "../../../config/constants";
import { useTranslation } from "react-i18next";

export const PixelAudience = () => {
    const { t } = useTranslation();
    const { mutate: exportPixelAudience, isLoading } = useMutation({
        mutationFn: services.exportPixelAudience,
        onSuccess: (res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link.href = `${constants.API}${res.data.response.file_path}`;
                link.setAttribute("download", ""); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                link.remove();

                toast.success("Audience report downloaded successfully!", {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => {
            console.log(err);
            toast.error("Failed to download the report.", {
                hideProgressBar: true,
            });
        },
    });

    const downloadAudienceReport = () => {
        exportPixelAudience();
    };

    return (
        <Box backgroundColor="white" minHeight="70%" borderRadius={2}>
            <Stack p={6} pt={8} spacing={3} maxWidth={320}>
                <Typography color="#06262D">
                    {" "}
                    {t("common.pixel_aud")}
                </Typography>

                <Button
                    sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#00C08B",
                        textTransform: "capitalize",
                        borderRadius: "6px",
                        px: 4,
                        "&:hover": {
                            backgroundColor: "#00C08B",
                            color: "#FFFFFF",
                        },
                        position: "relative",
                        top: "4px",
                    }}
                    disabled={isLoading}
                    onClick={() => downloadAudienceReport()}
                >
                    {t("common.export")}
                    {/* Export */}
                </Button>

                {isLoading && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: 9999999,
                        }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Stack>
        </Box>
    );
};
