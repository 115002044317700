import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";

import { constants } from "../../../../config/constants";
import { Box, Stack, Typography } from "@mui/material";

const CustomTick = ({ x, y, payload }) => {
    const { t } = useTranslation();
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                {payload.value} {t("common.currency")}
            </text>
        </g>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
        const data = payload.filter((h) => h.value > 0 && h.name !== "name");

        console.log({ data });
        // Customize the tooltip content
        return (
            <Stack
                padding={4}
                bgcolor="white"
                borderRadius="6px"
                minWidth="160px"
                spacing={4}
                border="1px solid #EEEFF4"
            >
                <Typography fontSize={18} fontWeight="bold">
                    Hour : {label}
                </Typography>
                <Stack spacing={2}>
                    {data.length > 0 ? (
                        data.map((hourChannel) => (
                            <Typography color={hourChannel.fill}>
                                {hourChannel.name} : {hourChannel.value}{" "}
                                {t("common.currency")}
                            </Typography>
                        ))
                    ) : (
                        <Typography>No Sales In This Hour</Typography>
                    )}
                </Stack>
            </Stack>
        );
    }

    return null;
};
const ComposedTrendingHours = ({
    data,
    container = { width: "100%", height: 300 },
    channelsSales,
    margin,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const segments = data[0] || [];

    const uniqueValuesSet = new Set(
        [
            ...channelsSales.map((item) => item.name),
            ...Object.keys(segments).filter(
                (key) => !channelsSales.some((item) => item.name === key)
            ),
        ].filter((s) => s !== "name")
    );

    const uniqueValuesArray = Array.from(uniqueValuesSet) || [];

    return (
        <ResponsiveContainer width={container.width} height={container.height}>
            <BarChart
                barSize={20}
                width={500}
                height={300}
                data={data}
                margin={
                    margin || {
                        top: 0,
                        right: isArabic ? 60 : 0,
                        left: isArabic ? 0 : 60,
                        bottom: 0,
                    }
                }
            >
                <XAxis dataKey="name" tickLine={false} reversed={isArabic} />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    unit={t("common.currency")}
                    orientation={isArabic ? "right" : "left"}
                    tick={(props) => <CustomTick {...props} />}
                />
                <Tooltip
                    wrapperStyle={{ outline: "none" }}
                    content={<CustomTooltip />}
                    cursor={false}
                />
                {uniqueValuesArray.map((barKey, index) => (
                    <Bar
                        dataKey={barKey}
                        stackId="a"
                        fill={
                            barKey === "total_sales"
                                ? "#F2F3F7"
                                : constants.pieColors[index]
                        }
                        radius={[2, 2, 0, 0]}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export { ComposedTrendingHours };
